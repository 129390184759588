@import "../../assets/styles/utils";

header{
  transition: all .6s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  &.white{
    .header::after{
      opacity: 1;
    }
  }
  .header{
    position: relative;
    z-index: 10;
    &::after{
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
      transition: all .4s;
      background-image: linear-gradient(top, rgba(255,255,255,.8),rgba(255,255,255,0));
      transform-origin: top;
      pointer-events: none;
      @include res(height,30vh,(md:250px,sm:150px,xs:150px));
    }
    .logo{
      position: absolute;
      z-index: 10;
      @include res(top,.4rem,(md:26px,sm:18px,xs:10px));
      @include res(left,.5rem, (md:44px,sm:32px,xs:20px));
      img{
        display: block;
        height: auto;
        @include res(width,1.8rem, (md:125px,sm:90px,xs:60px));
      }
    }
    .menu{
      display: flex;
      align-items: center;
      position: absolute;
      z-index: 10;
      @include res(display,flex,(md:none));
      @include res(top,.5rem);
      @include res(right,.75rem);
      li{
        position: relative;
        @include res(padding-left,.25rem);
        @include res(padding-right,.25rem);
        &:hover{
          .nav-title{
            color: $color-main-light;
            a{
              color: $color-main-light;
            }
          }
          .sub-nav{
            transform: translateX(-50%) translateY(0);
            opacity: 1;
            visibility: visible;
          }
        }
        .nav-title{
          color: $color-main;
          transition: all $anime-duration;
          @include res(font-size, .22rem);
          a{
            color: #085039;
            
          }
          .iconfont{
            border-radius: 50%;
            border: solid $color-main-light;
            color: $color-main-light;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all $anime-duration;
            @include res(border-width,.01rem,(md:1px));
            @include res(font-size,.22rem);
            @include res(width,.37rem);
            @include res(height,.37rem);
            &:hover{
              background-color: $color-main-light;
              color: #fff;
            }
          }
        }
        .sub-nav{
          position: absolute;
          // width: 100%;
          left: 50%;
          top: 100%;
          text-align: center;
          background-color: #fff;
          box-shadow: .03rem .03rem .1rem rgba(0,0,0,.1);
          line-height: 1.1;
          overflow: hidden;
          transform: translateX(-50%) translateY(.1rem);
          opacity: 0;
          visibility: hidden;
          transition: all .3s;
          white-space: nowrap;
          @include res(min-width,1.5rem);
          @include res(border-radius,.1rem,(md:10px));
          @include res(font-size,.16rem);
          a{
            display: block;
            transition: all .3s;
            background-image:none;
            position: relative;
            z-index: 1;
            @include res(padding-left,.1rem);
            @include res(padding-right,.1rem);
            @include res(padding-top,.1rem);
            @include res(padding-bottom,.1rem);
            color: #085039;
            
            &::after{
              content: '';
              display: block;
              width: 100%;
              height: 100%;
              background-image: linear-gradient(to right, #8bd1b0,#9dcfd4);
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              transition: all .3s;
              opacity: 0;
            }
            &:hover{
              color: #fff;
              font-weight: bold;
              &::after{
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  .menu-icon {
    align-items: center;
    border-radius: 50px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.7s $anime-bezier;
    border: 1px solid $color-main;
    position: fixed;
    z-index: 999;
    transition: all $anime-duration;
    @include res(display,none,(md:flex));
    @include res(top, 80px, 10 / 80);
    @include res(right, 50px, 10 / 50);
    @include res(padding-left, 25px, 10 / 25);
    @include res(padding-right, 25px, 10 / 25);
    @include res(height, 50px, 35 / 50);
    @include res(font-size,16px, 14 / 16);
    &.scroll{
      background-color: rgba(#ECF9F2,.8);
      border: 1px solid $color-main;
      .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
        background-color: $color-main;
      }
      .hamburger-t{
        color: $color-main;
      }
    }
    &.active {
      border: 1px solid $color-main;
      background-color: transparent;
      .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
        background-color: $color-main;
      }
      .hamburger-t {
        color: $color-main;
        span {
          transform: translateY(-100%);
        }
      }
    }
    .hamburger {
      > div::after {
        width: 50%;
        right: 0;
      }
      &.active {
        width: 16px;
        margin-left: 7px;
        > div {
          height: 3px;
          width: 16px;
          &::before {
            height: 3px;
          }
          &::after {
            height: 3px;
            width: 100%;
          }
        }
      }
    }
    .hamburger-t {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      line-height: 1.2;
      color: $color-main;
      @include res(height, 18px);
      @include res(margin-left, 10px, 5 / 10);
      span {
        display: inline-block;
        transition: transform 0.45s $anime-bezier;
        @include res(padding-top,0,(xs: 2px,));
      }
    }
  }
  .mob-header{
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 998;
    height: 100vh;
    overflow-x: hidden;
    background: url(./images/mob_nav_bg.jpg) no-repeat center;
    background-size: cover;
    transition: all $anime-duration;
    @include res(padding-top, 200px, 100 / 200);
    @include res(padding-bottom, 100px, 40 / 100);
    @include res(padding-left, 50px, 20 / 50);
    @include res(padding-right, 50px, 20 / 50);
    @include res(width,60%,(sm:80%,xs:100%));
    &.show{
      transform: translateX(-100%);
    }
    ul{
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      li{
        color: $color-main;
        border-bottom: 1px solid rgba(8,80,57,.4);
        &.active{
          .title .iconfont{
            transform: rotate(90deg);
          }
        }
        .title{
          align-items: center;
          justify-content: space-between;
          line-height: 50px;
          font-family: 'Seed-bold';
          @include res(padding-left,20px, 15 / 20);
          @include res(display,flex);
          @include res(font-size,22px, 18 / 22);
          a{
            flex-grow: 1;
          }
          .iconfont{
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            transition: all $anime-duration;
            transform-origin: center;
            @include res(width,50px);
            @include res(height,50px);
          }
        }
        .sub-nav{
          display: none;
          @include res(padding-left, 50px, 30 / 50);
          @include res(padding-right, 50px, 20 / 50);
          @include res(padding-bottom, 30px, 10 / 30);
          a{
            display: block;
          }
        }
      }
    }
  }
  .mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    top:0;
    left: 0;
    z-index: 997;
    display: none;
  }
}