@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/swiper-bundle.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import "../fonts/iconfont.css";

// The LINE seed font used on this website is owned by LINE CORP.
@font-face {
  font-family: 'Seed';
  src: url('../fonts/LINESeedSans_Rg.woff') format('woff'),
    url('../fonts/LINESeedSans_Rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Seed-bold';
  src: url('https://ltd-client-video.oss-cn-beijing.aliyuncs.com/LINE-Seed-Sans-KR-Bold.woff') format('woff'),
    url('https://ltd-client-video.oss-cn-beijing.aliyuncs.com/LINE-Seed-Sans-KR-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
  &.blur{
    filter: blur(5px);
    pointer-events: none;
  }
}
html{
  font-size: 62.5%;
}
body {
  font-family: "Seed","PingFang SC","Microsoft YaHei";
  line-height: 1.75;
  position: relative;
  color: $color-main;
  background-color: #fff;
  font-size: 12px;
  vertical-align: middle;
  background: url(../images/defult_bg.jpg) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
}
html,body{
  &::-webkit-scrollbar {
    @include res(width,0px); 
  }
}
.comp-root{
  overflow-x: hidden;
  @include res(font-size, .2rem, (md:18px));
}

.jason-map-active{
  transform: translate3d(-$jason-map-width,0,0) !important;
}
.yincang{
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
@keyframes mouse{
  0%{ transform: translateY(0); opacity:1}
  100%{ transform: translateY(.05rem); opacity: 0;}
}
@keyframes scrollicon {
  0%{
    opacity: .4;
    transform: translateY(0rem);
  }
  50%{
    opacity: .8;
    transform: translateY(.15rem);
  }
  100%{
    opacity: .4;
    transform: translateY(0rem);
  }
}
@keyframes shubiao {
  0%{
    top: 0;
    transform: translateX(-50%) translateY(-100%);
  }
  30%{
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  70%{
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  100%{
    top: 101%;
    transform: translateX(-50%) translateY(0);
  }
}
.footer-tran{
  transition: all .6s;
}
.fixed-footer{
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
  transform: translateY(100%);
  transition: all .6s;
  @include res(position,fixed,(md:static));
  @include res(transform,translateY(100%),(md:translateY(0)));
  &.show{
    transform: translateY(0);
  }
}

.scroll-icon{
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  @include res(display,block,(md:none));
  .iconfont{
    display: inline-block;
    animation: scrollicon 2s infinite;
    font-weight: bold;
    @include res(font-size,.3rem);
  }
}

.underline{
  display: inline-block;
  font-weight: bold;
  position: relative;
  z-index: 1;
  &::after{
    content: '';
    display: block;
    width: 100%;
    background-color: #b0eec7;
    position: absolute;
    bottom: 10%;
    left: 0;
    z-index: -1;
    @include res(height,.12rem,(md:8px));
  }
}

.emphasize{
  font-family: 'Seed-bold';
  color: $color-main-green;
  position: relative;
  display: inline-block;
  @include res(padding-left,.3rem,(md:20px));
  &::before{
    content: '\e648';
    font-family: 'iconfont';
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1;
    @include res(transform,translateY(-15%),(md:translateY(0)));
    @include res(font-size,.3rem,(md:20px));
  }
}

.haszw-img{
  position: relative;
  overflow: hidden;
  img{
    display: block;
    width: 100%;
    height: auto;
    opacity: 0;
  }
  b,a{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    transition: all .3s;
  }
}

.common-swiper-pagination{
  text-align: center !important;
  position: static !important;
  @include res(margin-top,.3rem,(md:20px,xs:10px));
  .swiper-pagination-bullet{
    position: relative;
    background: #25b171;
    opacity: 1;
    @include res(width,.06rem,(md:6px));
    @include res(height,.06rem,(md:6px));
    @include res(margin,0 .06rem,(md:0 8px));
    &::after{
      content: '';
      display: block;
      border-radius: 50%;
      border: solid #7bd3ab;
      position: absolute;
      top: 50%;
      left:50%;
      opacity: 0;
      transform: translate3d(-50%,-50%,0);
      transition: all $anime-duration;
      @include res(border-width, .01rem,(md:1px));
      @include res(width,.16rem,(md:16px));
      @include res(height,.16rem,(md:16px));
    }
    &.swiper-pagination-bullet-active{
      &::after{
        opacity: 1;
      }
    }
  }
}

.swiper-comp-btn{
  .swiper-button-next,.swiper-button-prev{
    border-radius: 50%;
    background-color: #ffffff;
    transition: all .3s;
    color: $color-main;
    @include res(width,.32rem,(md:32px));
    @include res(height,.32rem,(md:32px));
    &::after{
      font-family: 'iconfont';
      @include res(font-size,.16rem,(md:16px));
    }
    &:hover{
      color: #fff;
      background-color: $color-main;
    }
  }
  .swiper-button-next{
    &::after{
      content: '\e600';
    }
  }
  .swiper-button-prev{
    &::after{
      content: '\e601';
    }
  }
}

.common-title{
  font-family: 'Seed-bold';
  line-height: 1.4;
  text-transform: uppercase;
  @include res(text-align,null,(sm:center));
  @include res(margin-bottom,.5rem,(md:30px,xs:20px));
  @include res(font-size,.6rem,(md:40px,xs:30px));
  span{
    display: inline-block;
    background-image: linear-gradient(to right, #25b170,#49acb6);
    -webkit-background-clip: text;
    color: transparent;
  }
}

.common-link{
  display: flex;
  align-items: center;
  justify-content: center;
  background:linear-gradient(left,#25b170,#49acb6);
  color: #fff;
  font-family: 'Seed-bold';
  transition: all $anime-duration;
  white-space: nowrap;
  box-sizing: border-box;
  @include res(border-radius,.5rem,(md:50px));
  @include res(font-size,.16rem,(md:16px));
  @include res(width,2.1rem, (md:175px));
  @include res(padding-top,.06rem,(md:6px));
  @include res(padding-right,.06rem,(md:6px));
  @include res(padding-bottom,.06rem,(md:6px));
  @include res(padding-left,.3rem, (md:15px));
  &:hover{
    .icon{
      @include res(width,.55rem,(md:40px));
      .iconfont{
        opacity: 0;
        transform: translateX(.1rem);
      }
      &::after{
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    transition: all $anime-duration;
    @include res(margin-left,.15rem,(md:10px));
    @include res(width,.25rem,(md:25px));
    @include res(height,.55rem,(md:40px));
    .iconfont{
      opacity: 1;
      transition: all .5s cubic-bezier(.19,1,.22,1);
      @include res(font-size,.2rem,(md:16px));
    }
    &::after{
      content: '\e600';
      font-family: 'iconfont';
      display: flex;
      align-items: center;
      justify-content: center;
      color: #48acb5;
      border-radius: 50px;
      background-color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
      transform: scale(0);
      transform-origin: center;
      transition: opacity .5s cubic-bezier(.19,1,.22,1),transform .5s cubic-bezier(.19,1,.22,1);
      z-index: -1;
      @include res(font-size,.2rem,(md:16px));
      @include res(width,.55rem,(md:40px));
      @include res(height,.55rem,(md:40px));
    }
  }
}

//新闻公共样式
.news-subnav{
  left: .46rem;
  z-index: 10;
  justify-content: center;
  transition: filter .6s;
  @include res(position,absolute,(md:static));
  @include res(display,block,(md:flex));
  @include res(top,32%);
  @include res(padding-left,0,(md:50px,sm:35px,xs:20px));
  @include res(padding-right,0,(md:50px,sm:35px,xs:20px));
  @include res(padding-top,0,(md:180px,sm:120px,xs:60px));
  @include res(margin-bottom,0,(md:60px,sm:50px,xs:40px));
  a{
    display: flex;
    box-sizing: border-box;
    border-radius: 50px;
    background-image: linear-gradient(to right,#a5dece,#c3f4ce);
    transition: all .3s;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    position: relative;
    @include res(padding,.04rem,(md:4px));
    @include res(width,1.74rem,(md:150px,xs:140px));
    @include res(margin-bottom,.2rem,(md:0));
    @include res(padding-right,.15rem,(md:15px));
    &:not(:last-child){
      @include res(margin-right,0,(md:10px));
    }
    &::after{
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      border-radius: 50px;
      background-color: $color-main;
      opacity: 0;
      transition: all .3s;
      box-shadow: 0 .1rem .1rem rgba(#b5eace,.5);
      @include res(top,-.02rem,(md:-2px));
      @include res(left,-.02rem,(md:-2px));
      @include res(width,calc(100% + .04rem),(md:calc(100% + 4px)));
      @include res(height,calc(100% + .04rem),(md:calc(100% + 4px)));
    }
    .iconfont{
      display: flex;
      flex-shrink: 0;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background-color: #e2f7eb;
      color: $color-main-light;
      transition: all .3s;
      @include res(font-size,.28rem,(md:24px,xs:20px));
      @include res(width,.42rem,(md:38px,xs:30px));
      @include res(height,.42rem,(md:38px,xs:30px));
    }
    span{
      flex-grow: 1;
      display: block;
      font-weight: bold;
      text-transform: uppercase;
      @include res(padding-top,.06rem);
    }
    &:hover{
      box-shadow: inset 0.2rem -0.2rem 0.4rem rgba(#88c9b8,.6);
      .iconfont{
        background-color: #fff;
      }
    }
    &.active{
      box-shadow: inset 0.2rem -0.2rem 0.4rem rgba(#88c9b8,.6);
      &::after{
        opacity: 1;
      }
      .iconfont{
        background-color: #fff;
      }
    }
  }
}

.news-fixed-cont{
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  top: 0;
  left: 0;
  transition: filter .6s;
  @include res(position,fixed,(md:static));
  @include res(display,flex,(md:block));
  @include res(height,100vh,(md:auto));
  @include res(padding-left,15%,(md:50px,sm:35px,xs:20px));
  @include res(padding-right,0,(md:50px,sm:35px,xs:20px));
  @include res(padding-top,1.3rem,(md:30px,sm:60px,xs:40px));
  @include res(padding-bottom,0,(md:20px,xs:10px));
  &.abs{
    position: absolute;
    bottom: 0;
    top: auto;
  }
  .list{
    overflow-y: visible;
    @include res(overflow-x,hidden,(md:visible));
    @include res(padding-bottom,.5rem);
    @include res(margin-bottom,.2rem);
    ul{
      display: inline-flex;
      align-items: stretch;
      @include res(width,auto,(md:100%));
      @include res(justify-content,flex-start,(md:space-between));
      @include res(flex-wrap,nowrap,(md:wrap));
    }
  }
  .news-contral{
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    @include res(display,flex,(md:none));
    @include res(padding-right,7.8%);
    .progress{
      position: relative;
      background-color: #fff;
      @include res(height,.03rem);
      @include res(width,4.25rem);
      div{
        position: absolute;
        height: 100%;
        z-index: 3;
        background-color: $color-main;
      }
    }
    .btn{
      padding: .05rem;
      white-space: nowrap;
      display: flex;
      align-items: center;
      background-color: #ffffff;
      border-radius: .5rem;
      box-shadow: 0px 0px .1rem rgba(8,80,57,.2);
      transition: all $anime-duration;
      cursor: pointer;
      &:hover{
        box-shadow: 0px 0px .15rem rgba(8,80,57,.4);
      }
      .icon{
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        background-color: #26b171;
        transition: all $anime-duration;
        @include res(margin-right,.15rem);
        @include res(width,.32rem);
        @include res(height,.32rem);
        .go{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 100%;
          background-color: #26b171;
          transform-origin: left center;
          transition: all .5s cubic-bezier(.19,1,.22,1);
          border-radius: 50%;
          .iconfont{
            color: #fff;
            @include res(font-size, .2rem);
          }
        }
        .back{
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          height: 100%;
          background-color: $color-main;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
          transform-origin: right center;
          transition: all .5s cubic-bezier(.19,1,.22,1);
          transform: scale(0);
          .iconfont{
            color: #fff;
            @include res(font-size, .2rem);
          }
        }
      }
      .text{
        position: relative;
        font-family: 'Seed-bold';
        overflow: hidden;
        line-height: 1;
        @include res(margin-right, .2rem);
        .go{
          color: #26b171;
          transition: all $anime-duration;
          position: absolute;
          top: 0;
          left: 0;
        }
        .back{
          color: $color-main;
          transform: translateY(100%);
          transition: all $anime-duration;
        }
      }
      &.back-btn{
        .icon{
          background-color: $color-main;
          .go{
            transform: scale(0);
          }
          .back{
            transform: scale(1);
          }
        }
        .text{
          .go{
            transform: translateY(-100%);
          }
          .back{
            transform: translateY(0);
          }
        }
      }
    }
  }
}

//quality公共样式
.quality-left-menu{
  top: 57%;
  left: .45rem;
  z-index: 10;
  transition: opacity .6s,transform .6s;
  justify-content: flex-start;
  align-items: flex-start;
  @include res(position,absolute,(md:static));
  @include res(transform,translateY(-50%),(md:translateY(0)));
  @include res(display,block,(md:flex));
  @include res(font-size,.18rem,(md:18px,sm:16px));
  @include res(overflow-x,null,(md:auto));
  @include res(padding-left,0,(md:50px,sm:35px,xs:20px));
  @include res(padding-right,0,(md:50px,sm:35px,xs:20px));
  @include res(padding-top,0,(md:210px,sm:180px,xs:100px));
  @include res(padding-bottom,0,(md:10px));
  li{
    &:not(:last-child){
      @include res(margin-bottom,.2rem,(md:0px));
      @include res(margin-right,0,(md:20px,xs:10px));
    }
    &:hover a{
      box-shadow: inset 0.2rem -0.2rem 0.4rem rgba(#88c9b8,.6);
      .iconfont{
        background-color: #fff;
      }
    }
    &.active a{
      font-weight: bold;
      box-shadow: inset 0.2rem -0.2rem 0.4rem rgba(#88c9b8,.6);
      &::after{
        opacity: 1;
      }
      .iconfont{
        background-color: #fff;
      }
    }
    a{
      display: block;
      text-align: center;
      white-space: nowrap;
      background-image: linear-gradient(to right,#a5dece,#c3f4ce);
      transition: all .3s;
      border-radius: 50px;
      position: relative;
      @include res(width,3.26rem,(md:auto));
      @include res(height,.46rem,(md:auto));
      @include res(line-height,.46rem,(md:1.6));
      @include res(padding,0,(md:10px 20px,xs:8px 15px));
      &::after{
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        border-radius: 50px;
        background-color: $color-main;
        opacity: 0;
        transition: all .3s;
        box-shadow: 0 .1rem .1rem rgba(#b5eace,.5);
        @include res(top,-.02rem,(md:-2px));
        @include res(left,-.02rem,(md:-2px));
        @include res(width,calc(100% + .04rem),(md:calc(100% + 4px)));
        @include res(height,calc(100% + .04rem),(md:calc(100% + 4px)));
      }
    }
  }
}

.common-pdf-list{
  @include res(margin-top,.2rem,(md:10px,xs:20px));
  li{
    border-bottom: solid $color-main-light;
    align-items: center;
    justify-content: space-between;
    @include res(display,flex);
    @include res(border-width,.01rem,(md:1px));
    @include res(padding-left,.1rem,(md:5px));
    @include res(padding-top,.07rem,(md:5px));
    @include res(padding-bottom,.07rem,(md:5px));
    &:first-child{
      border-top: solid $color-main-light;
      @include res(border-width,.01rem,(md:1px));
    }
    .title{
      flex-grow: 1;
      line-height: 1.2;
      @include res(font-size,.18rem,(md:16px,sm:16px,xs:14px));
    }
    .pdf-btn{
      flex-shrink: 0;
      display: flex;
      align-items: center;
      @include res(font-size,.18rem,(md:16px,xs:14px));
      @include res(margin-left,.1rem);
      a{
        transition: all .3s;
      }
      .btn-t:hover{
        color: $color-main-light;
      }
      .btn-icon{
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-main;
        color: #fff;
        @include res(margin-left,.2rem,(md:20px));
        @include res(width,.32rem,(md:30px));
        @include res(height,.32rem,(md:30px));
        &:hover{
          background-color: $color-main-light;
        }
      }
    }
  }
}

//内页叶子
.ny-leaf-cont{
  @include res(display,null,(md:none));
  &.show{
    .leaf{
      opacity: 1;
    }
  }
  &.next{
    .leaf img{
      transform: translateY(50%);
    }
  }
  .leaf{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity 1s linear,transform .2s linear;
    pointer-events: none;
    img{
      display: block;
      height: auto;
      transition: transform 1s;
    }
    &.leaf1{
      top: 1%;
      left: 25%;
      img{
        @include res(width,1.12rem, (md:70px,xs:60px));
      }
    }
    &.leaf2{
      top: 18%;
      left: 79%;
      img{
        @include res(width,1.87rem, (md:90px,xs:80px));
      }
    }
    &.leaf3{
      // top: 93%;
      top: 88%;
      left: 19%;
      img{
        @include res(width,2.21rem,(md:100px,xs:90px));
      }
    }
    &.leaf4{
      top: 8%;
      left: 86%;
      img{
        @include res(width,1.96rem);
      }
    }
    &.leaf5{
      top: 82%;
      left: 56%;
      img{
        @include res(width,2.49rem);
      }
    }
    &.leaf6{
      top: 1%;
      left: 21%;
      img{
        @include res(width,1.41rem);
      }
    }
    &.leaf7{
      top: 80%;
      left: 7%;
      img{
        @include res(width,1.87rem);
      }
    }
    &.leaf8{
      top: 77%;
      left: 19%;
      img{
        @include res(width,1.12rem);
      }
    }
  }
}


//solution内页公共样式
.article-solution-fixed-btn{
  position: absolute;
  top: 30%;
  z-index: 10;
  background-image: linear-gradient(to bottom,rgba(76,209,150,.5),rgba(76,209,150,0));
  border-top-right-radius: .5rem;
  border-bottom-right-radius: 2rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: all .6s;
  @include res(display,flex,(md:none));
  @include res(padding-left,.26rem);
  @include res(padding-right,.26rem);
  @include res(padding-top,.26rem);
  @include res(height,50vh);
  &.go-back{
    cursor: pointer;
    .icon{
      .mouse-circle,.back{
        transform: translateX(-100%);
      }
    }
    .text p{
      transform: translateX(-100%);
    }
  }
  .icon{
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    @include res(width,.56rem);
    @include res(height,.56rem);
    .mouse-circle{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform .3s;
      position: relative;
      .mouse{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-100%);
        animation: shubiao 2.5s infinite;
        img{
          display: block;
          height: auto;
          @include res(width,.16rem);
        }
        &::after{
          content: '';
          display: block;
          background-color: #5dbc8a;
          position: absolute;
          animation: mouse 1.5s infinite;
          @include res(width,.02rem,(md:2px));
          @include res(height,.05rem,(md:5px));
          @include res(top,.04rem,(md:4px));
          @include res(left,calc(50% - .01rem),(md:calc(50% - 1px)));
        }
      }
    }
    .back{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform .3s;
      .iconfont{
        color: $color-main-light;
        @include res(font-size,.24rem);
      }
    }
  }
  .text{
    writing-mode:tb-rl;
    color: #fff;
    font-family: 'Seed-bold';
    position: relative;
    overflow: hidden;
    @include res(font-size,.16rem);
    @include res(margin-top,.3rem);
    p{
      transition: transform .3s;
      &:last-child{
        position: absolute;
        top: 0;
        left: 100%;
      }
    }
  }
}

.article-solution-banner{
  .common-title{
    @include res(font-size,.5rem,(md:40px,xs:30px));
  }
  .cont{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    @include res(display,flex,(sm:block));
    .right-img{
      flex-shrink: 0;
      position: relative;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: .3rem;
      overflow: hidden;
      @include res(height,5.5rem,(md:300px));
      @include res(width,58.7%,(md:calc(40% + 50px),sm:calc(100% + 70px),xs:calc(100% + 40px)));
      @include res(margin-bottom,0,(sm:20px));
      @include res(margin-left,0,(sm:-35px,xs:-20px));
      @include res(margin-right,0,(md:-50px,sm:0));
      b{
        display: block;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      h3{
        position: absolute;
        top: 2%;
        right: 3%;
        z-index: 2;
        color: #fff;
        font-family: 'Seed-bold';
        text-transform: uppercase;
        @include res(font-size,.7rem,(md:38px,xs:30px));
      }
      &::before,&::after{
        content: '';
        display: block;
        width: 100%;
        height: 30%;
        position: absolute;
        left: 0;
        z-index: 1;
      }
      &::before{
        top: 0;
        background-image: linear-gradient(to bottom,rgba(255,255,255,.5),rgba(255,255,255,0));
      }
      &::after{
        bottom: 0;
        background-image: linear-gradient(to top,rgba(255,255,255,.5),rgba(255,255,255,0));
      }
    }
    .left-t{
      flex-shrink: 0;
      @include res(min-height,4rem,(md:0));
      @include res(width,36.3%,(md:58%,sm:100%));
      .sub-title{
        font-family: 'Seed-bold';
        @include res(font-size,.4rem,(md:30px,xs:20px));
        @include res(margin-bottom,.1rem,(md:5px));
      }
      .list{
        display: flex;
        flex-wrap: wrap;
        &.full{
          li{
            width: 100%;
            .t{
              .t-title{
                font-family: 'Seed-bold';
              }
            }
          }
        }
        li{
          display: flex;
          align-items: flex-start;
          @include res(min-width,50%,(xs:100%));
          @include res(margin-top,.08rem,(md:5px,xs:5px));
          .img{
            display: flex;
            align-items: center;
            flex-shrink: 0;
            @include res(width,.25rem,(md:25px,xs:20px));
            @include res(height,.34rem,(md:30px));
            @include res(margin-right,.1rem,(md:10px));
            img{
              display: block;
              width: 100%;
              height: auto;
            }
          }
          .t{
            flex-grow: 1;
            .t-title{
              line-height: 1.1;
              font-family: 'Seed-bold';
              @include res(padding-top,.06rem,(md:5px));
              @include res(padding-bottom,.06rem,(md:5px));
            }
            .t-desc{
              line-height: 1.1;
              // color: $color-main-light;
              color: #085039;
              @include res(font-size,.17rem,(md:16px,xs:14px));
            }
          }
        }
      }
      .desc{
        font-family: 'Seed-bold';
        line-height: 1.25;
        @include res(font-size,.24rem,(md:16px));
        @include res(margin-top,.8rem,(xs:20px));
      }
    }
  }
}
