@import "../../assets/styles/utils";

.ny-banner{
  @include res(height,100vh,(md:600px,sm:450px,xs:300px));
  .bg-img{
    height: 100%;
    display: block;
    background-position: center;
    background-size: cover;
    position: relative;
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.2);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .swiper{
    height: 100%;
    display: block;
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.2);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    .swiper-slide{
      background-position: center;
      background-size: cover;
      position: relative;
    }
    .swiper-pagination{
      bottom: 5%;
      .swiper-pagination-bullet{
        position: relative;
        background: #25b171;
        opacity: 1;
        @include res(width,.06rem,(md:6px));
        @include res(height,.06rem,(md:6px));
        @include res(margin,0 .06rem,(md:0 8px));
        &::after{
          content: '';
          display: block;
          border-radius: 50%;
          border: solid #7bd3ab;
          position: absolute;
          top: 50%;
          left:50%;
          opacity: 0;
          transform: translate3d(-50%,-50%,0);
          transition: all $anime-duration;
          @include res(border-width, .01rem,(md:1px));
          @include res(width,.16rem,(md:16px));
          @include res(height,.16rem,(md:16px));
        }
        &.swiper-pagination-bullet-active{
          &::after{
            opacity: 1;
          }
        }
      }
    }
  }
  .banner-text{
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 2;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include res(top,50%,(xs:40%));
    @include res(display,flex,(md:block));
    @include res(padding-left,1.1rem,(md:40px,sm:30px,xs:20px));
    @include res(padding-right,1.2rem, (md:40px,sm:30px,xs:20px));
    .left{
      flex-shrink: 0;
      position: relative;
      font-family: 'Seed-bold';
      line-height: 1;
      h1{
        text-transform: uppercase;
        @include res(font-size,.8rem,(md:40px,sm:36px,xs:30px));
      }
      h2{
        position: absolute;
        top: 100%;
        left: 0;
        text-transform: uppercase;
        @include res(font-size,.4rem,(md:30px,sm:24px,xs:20px));
      }
    }
    .line{
      flex-grow: 1;
      height: 1px;
      background-color: rgba(255,255,255,.5);
      @include res(display,block,(md:none));
      @include res(margin-left,.25rem, (md:10px));
    }
    .right{
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #fff;
      position: relative;
      overflow: hidden;
      @include res(display,flex,(md:none));
      @include res(width,.66rem, (md:50px));
      @include res(height,.66rem, (md:50px));
      .mouse{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-100%);
        animation: shubiao 2.5s infinite;
        img{
          display: block;
        }
        &::after{
          content: '';
          display: block;
          background-color: $color-main;
          position: absolute;
          animation: mouse 1.5s infinite;
          @include res(width,.02rem,(md:2px));
          @include res(height,.05rem,(md:5px));
          @include res(top,.06rem,(md:4px));
          @include res(left,calc(50% - .01rem),(md:calc(50% - 1px)));
        }
      }
    }
  }
}

