@import "../../assets/styles/utils";

.load {
  position: fixed;
  z-index: 19960427;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  text-align: center;
  &.load-end {
    transition: all .5s ease .8s;
    opacity: 0;
    visibility: hidden;
    .load-top {
      transform: translate3d(0,-100%,0);
    }
    .load-bottom {
      transform: translate3d(0,100%,0);
    }
  }
  .load-progress {
    position: absolute;
    height: 1px;
    width: 0;
    background-color: $color-main;
    top: 0;
    left: 0;
    right: auto;
    bottom: 0;
    margin: auto;
    z-index: 19981205;
  }
  .load-bottom, .load-top {
    height: 50%;
    width: 100%;
    transition: all .8s;
    position: relative;
  }
  .load-top {
    background-color: #fff;
    .load-logo {
      position: absolute;
      top: auto;
      left: 0;
      right: 0;
      bottom: 20px;
      margin: auto;
    }
  }
  .load-bottom {
    background-color: #fff;
    padding-top: 20px;
    dd, dt {
      display: inline-block;
      vertical-align: middle;
    }
    dd {
      font-size: 34px;
    }
    .load-num:before {
      content: attr(data-num);
    }
  }
}