@import "../../assets/styles/utils";

footer{
  .footer-nav{
    position: relative;
    z-index: 2;
    background-color: #dff6ee;
    border-radius: 20px;
    color: $color-main;
    @include res(display,none,(sm:none));
    @include res(padding-left,6.7%, 5 / 6.7);
    @include res(padding-right,6.7%, 5 / 6.7);
    @include res(padding-top,.85rem,(md:40px,sm:30px,xs:20px));
    @include res(padding-bottom,.75rem,(md:40px,sm:30px,xs:20px));
    ul{
      align-items: flex-start;
      justify-content: space-between;
      @include res(display,flex);
      li{
        .title{
          font-family: 'Seed-bold';
          transition: all $anime-duration;
          @include res(font-size,.26rem,(md:20px,xs:18px));
          @include res(margin-bottom, .3rem, (md:20px,xs:15px));
          &:hover{
            color: $color-main-light;
          }
        }
        .sub-nav{
          @include res(font-size, .2rem,(md:16px));
          a{
            display: block;
            transition: all $anime-duration;
            span{
              display: inline-block;
              position: relative;
              &::after{
                content: '';
                display: block;
                width: 100%;
                background-color: $color-main;
                position: absolute;
                left: 0;
                transform: scaleX(0);
                transform-origin: right;
                transition: transform $anime-duration;
                @include res(height,.02rem,(md:2px));
                @include res(bottom,.03rem,(md:3px));
              }
            }
            &:hover{
              span{
                &::after{
                  transform: scaleX(1);
                  transform-origin: left;
                }
              }
            }
          }
        }
        &:nth-child(2){
          .sub-nav{
            align-items: stretch;
            justify-content: flex-start;
            flex-wrap: wrap;
            @include res(display,flex);
            @include res(width,4.4rem);
            a{
              @include res(width,50%);
            }
          }
        }
      }
    }
  }
  .footer{
    position: relative;
    z-index: 1;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    line-height: 1.5;
    @include res(display,flex,(sm:block));
    @include res(padding-left,6.25%, 5 / 6.25);
    @include res(padding-right,3.9%, 5 / 3.9);
    @include res(padding-top,.2rem,(md:20px,sm:20px,xs:20px));
    @include res(padding-bottom,.25rem, (md:20px,sm:20px,xs:20px));
    &::after{
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      background:linear-gradient(left,#25b170,#49acb6);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      @include res(height,120%,(sm:100%));
    }
    .footer-logo{
      flex-shrink: 0;
      @include res(margin-bottom,null,(sm:30px,xs:20px));
      img{
        display: block;
        height: auto;
        @include res(width,1.7rem, (md:130px,sm:116px,xs:100px));
        @include res(margin,null,(sm:0 auto));
        @include res(margin-bottom,.1rem,(md:10px));
      }
    }
    .footer-contact{
      flex-grow: 1;
      position: relative;
      // @include res(display,null,(sm:none));
      @include res(margin-left,.9rem,(md:20px,xs:0));
      @include res(margin-right,1rem,(md:20px,xs:0));
      &::after{
        content: '';
        height: 54%;
        position: absolute;
        left: 50%;
        top: 23%;
        background-color: rgba(255,255,255,.3);
        @include res(display,block,(md:none));
        @include res(width,.01rem,(md:1px));
      }
      .desc{
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        @include res(line-height,1.8, 1.6 / 1.8);
        @include res(font-size,.16rem,(md:16px));
        @include res(text-align,null,(sm:center));
        @include res(display,flex,(md:block));
        .item{
          @include res(width,46%,(md:100%));
          &:first-child{
            @include res(margin-bottom,0,(md:.25rem));
          }
          .title{
            @include res(margin-bottom,.05rem,(md:5px));
          }
          >div{
            &:first-child{
              @include res(margin-bottom,.25rem);
            }
          }
        }
      }
      .link{
        color: rgba(255,255,255,.6);
        align-items: center;
        @include res(font-size,.18rem,(md:16px));
        @include res(text-align,null,(sm:center));
        @include res(justify-content,flex-start,(sm:center));
        @include res(margin-top,.15rem,(md:20px,sm:15px));
        @include res(display,flex,(xs:block));
        @include res(padding-left,54%,(md:0));
        a{
          display: flex;
          justify-content: center;
          @include res(margin-bottom,0,(xs:10px));
          &:hover{
            color: #fff;
          }
          &:not(:last-child){
            margin-right: .5rem;
          }
          img{
            display: block;
            width: auto;
            @include res(height,.3rem,(md:30px));
          }
        }
      }
    }
    .footer-copyright{
      flex-shrink: 0;
      @include res(font-size, .16rem,(md:16px));
      @include res(margin-top,null,(sm:20px,xs:10px));
      @include res(text-align,right,(sm:center));
      .icon{
        line-height: 1;
        @include res(margin-bottom,.1rem,(md:10px));
        .iconfont{
          transition: all .3s;
          display: inline-block;
          @include res(margin-left,.1rem,(md:0));
          @include res(font-size,.6rem,(md:40px));
          &:hover{
            transform: translateY(-.05rem);
          }
        }
      }
      .t{
        br{
          @include res(display,null,(sm:none));
        }
      }
      .link{
        @include res(margin-top,.25rem,(md:10px,xs:5px));
        span{
          @include res(margin-left,.05rem);
        }
        a{
          color: rgba(255,255,255,.7);
          transition: all $anime-duration;
          &:hover{
            color: #fff;
          }
        }
        .site-map{
          @include res(display,null,(md:none));
        }
      }
    }
  }
}