.pro-article-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: none;
}

.pro-article-popup .popup-mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(8, 80, 57, 0.38);
}

.pro-article-popup .flex-c {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pro-article-popup .cont {
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  border-radius: 0.2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 0.7rem;
  width: 9.1rem;
}

@media (max-width: 1024px) {
  .pro-article-popup .cont {
    border-radius: 15px;
  }
}

@media (max-width: 767px) {
  .pro-article-popup .cont {
    border-radius: 10px;
  }
}

@media (max-width: 767px) {
  .pro-article-popup .cont {
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .pro-article-popup .cont {
    padding-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .pro-article-popup .cont {
    padding-left: 10px;
  }
}

@media (max-width: 767px) {
  .pro-article-popup .cont {
    padding-right: 5px;
  }
}

@media (max-width: 1024px) {
  .pro-article-popup .cont {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .pro-article-popup .cont {
    width: 90%;
  }
}

.pro-article-popup .cont .popup-close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 0.5rem;
  height: 0.5rem;
  top: 0.2rem;
  right: 0.3rem;
}

@media (max-width: 1024px) {
  .pro-article-popup .cont .popup-close {
    width: 40px;
  }
}

@media (max-width: 1024px) {
  .pro-article-popup .cont .popup-close {
    height: 40px;
  }
}

@media (max-width: 767px) {
  .pro-article-popup .cont .popup-close {
    right: 10px;
  }
}

.pro-article-popup .cont .popup-close:hover .iconfont {
  transform: rotate(90deg);
}

.pro-article-popup .cont .popup-close .iconfont {
  transition: all .3s;
  transform-origin: center;
  font-size: 0.2rem;
}

@media (max-width: 1024px) {
  .pro-article-popup .cont .popup-close .iconfont {
    font-size: 16px;
  }
}

.pro-article-popup .cont .popup-title {
  font-family: 'Seed-bold';
  font-size: 0.4rem;
}

@media (max-width: 1024px) {
  .pro-article-popup .cont .popup-title {
    font-size: 22px;
  }
}

.pro-article-popup .cont .popup-title span {
  display: inline-block;
  background-image: linear-gradient(to right, #25b170, #49acb6);
  -webkit-background-clip: text;
  color: transparent;
}

.pro-article-popup .cont .common-pdf-list {
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 0.3rem;
}

@media (max-width: 1024px) {
  .pro-article-popup .cont .common-pdf-list {
    padding-right: 5px;
  }
}

.pro-article-popup .cont .common-pdf-list::-webkit-scrollbar {
  width: 0.06rem;
}

@media (max-width: 1024px) {
  .pro-article-popup .cont .common-pdf-list::-webkit-scrollbar {
    width: 6px;
  }
}

.pro-article-popup .cont .common-pdf-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(40, 177, 118, 0.3);
}

.pro-article-popup .cont .common-pdf-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
  border-radius: 0;
  background: rgba(0, 0, 0, 0);
}

.pro-article-popup .cont .common-pdf-list li {
  padding-top: 0.12rem;
  padding-bottom: 0.12rem;
}

@media (max-width: 1024px) {
  .pro-article-popup .cont .common-pdf-list li {
    padding-top: 5px;
  }
}

@media (max-width: 1024px) {
  .pro-article-popup .cont .common-pdf-list li {
    padding-bottom: 5px;
  }
}
