@import "../../assets/styles/utils";

.pro-article-popup{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: none;
  .popup-mask{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(8,80,57,.38);
  }
  .flex-c{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cont{
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    @include res(border-radius,.2rem,(md:15px,xs:10px));
    @include res(padding-top,.5rem,(xs:20px));
    @include res(padding-bottom,.5rem,(xs:20px));
    @include res(padding-left,1rem,(xs:10px));
    @include res(padding-right,.7rem,(xs:5px));
    @include res(width,9.1rem,(md:80%,xs:90%));
    .popup-close{
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include res(width,.5rem,(md:40px));
      @include res(height,.5rem,(md:40px));
      @include res(top, .2rem);
      @include res(right, .3rem,(xs:10px));
      &:hover{
        .iconfont{
          transform: rotate(90deg);
        }
      }
      .iconfont{
        transition: all .3s;
        transform-origin: center;
        @include res(font-size,.2rem,(md:16px));
      }
    }
    .popup-title{
      font-family: 'Seed-bold';
      @include res(font-size,.4rem,(md:22px));
      span{
        display: inline-block;
        background-image: linear-gradient(to right, #25b170,#49acb6);
        -webkit-background-clip: text;
        color: transparent;
      }
    }
    .common-pdf-list{
      max-height: 50vh;
      overflow-y: auto;
      @include res(padding-right,.3rem,(md:5px));
      &::-webkit-scrollbar {
        @include res(width,.06rem,(md:6px));
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        // box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        background: rgba(#28b176,.3);
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0,0,0,0);
        border-radius: 0;
        background: rgba(0,0,0,0);
      }
      li{
        @include res(padding-top,.12rem,(md:5px));
        @include res(padding-bottom,.12rem,(md:5px));
      }
    }
  }
}