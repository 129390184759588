@font-face {
  font-family: "iconfont"; /* Project id 3837433 */
  src: url('iconfont.woff2?t=1687332684686') format('woff2'),
       url('iconfont.woff?t=1687332684686') format('woff'),
       url('iconfont.ttf?t=1687332684686') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tishi:before {
  content: "\e648";
}

.icon-fuwu:before {
  content: "\e606";
}

.icon-flow-others:before {
  content: "\e65b";
}

.icon-wailian_icon:before {
  content: "\e63e";
}

.icon-chakan2:before {
  content: "\e605";
}

.icon-whatsapp:before {
  content: "\e646";
}

.icon-skype-fill:before {
  content: "\e882";
}

.icon-xiangxia:before {
  content: "\e636";
}

.icon-guanbi:before {
  content: "\e60e";
}

.icon-danchuang:before {
  content: "\e842";
}

.icon-zhankai:before {
  content: "\e626";
}

.icon-xiazai:before {
  content: "\e60b";
}

.icon-xiazai1:before {
  content: "\e65c";
}

.icon-xinwen_o:before {
  content: "\ebca";
}

.icon-ICAtubiao_huodongricheng:before {
  content: "\e60a";
}

.icon-jianzhu:before {
  content: "\e604";
}

.icon-ditu-dibiao:before {
  content: "\e608";
}

.icon-cangku3:before {
  content: "\e6b9";
}

.icon-gongchang:before {
  content: "\e83b";
}

.icon-right-arrow:before {
  content: "\e603";
}

.icon-youjiantou1:before {
  content: "\e602";
}

.icon-youjiantou_:before {
  content: "\e68c";
}

.icon-youxiang:before {
  content: "\e62a";
}

.icon-youjiantou:before {
  content: "\ee39";
}

.icon-arrow-downward:before {
  content: "\e600";
}

.icon-arrow-downward1:before {
  content: "\e601";
}

